<template>
<div>
  <Banner :banner="bannerImage" />
  <div class="limiter" v-if="!isVisitorLogin">
    <div class="container-login100" :style="`background-image: url('${speedSizeDomain}/assets/img/agency-login-back.jpg');`">
      <div class="wrap-login100 pt-190 pb-30">
        <div class="wrap-input100 mb-3" v-if="!visitorSid">
          <b-form-input type="text" class="input100" :state="!!pnr" @keypress="(e)=>checkPnr(e)" :placeholder="$t('check-booking.add-pnr')" v-model="pnr"></b-form-input>
          <span class="symbol-input100">
            <i class="fa fa-book"></i>
          </span>
        </div>
        <div class="wrap-input100 mb-3" v-if="visitorSid && !isVisitorLogin">
          <b-form-input type="text" class="input100" :state="!!verifyCode" @keypress="(e)=>checkCode(e)" placeholder="Code" v-model="verifyCode"></b-form-input>
          <span class="symbol-input100">
            <i class="fa fa-lock"></i>
          </span>
        </div>
        <div class="text-center w-full error-message" v-if="errorMessage">
          {{ errorMessage }}
        </div>

        <div class="container-login100-form-btn pt-3" v-if="!visitorSid">
          <b-button class="login100-form-btn" @click="requestLogin">
            {{$t("login.login")}}
          </b-button>
        </div>

        <span v-else-if="!isVisitorLogin" class="mt-3">
          <div class="container-login100-form-btn">
            <b-button class="login100-form-btn" @click="requestVerifyCode">
              {{$t("login.verify-code")}}
            </b-button>
          </div>

          <div class="text-center w-full pt-25 pb-230">
            <p class="txt1">
            {{$t("login.verify-code-message")}}
            </p>
          </div>
        </span>
        <div class="container-login100-form-btn mt-3">
          <b-button class="login100-form-btn" @click="cancelLogin">
            {{$t("login.cancel")}}
          </b-button>
        </div>
      </div>
    </div>
  </div>

  <div class="my-5 hotelplusflight" v-else>

    <div class="container mb-5 booking-info-tab">
      <div class="row">
        <div class="col-12">
          <b-tabs card fill v-model="tabIndex">
            <b-tab>
              <template #title>
                <strong class="tab-title">{{$t("check-booking.overall-details")}}</strong>
              </template>
              <b-card-text>

                <div class="container mb-5">
                  <h4 class="font-light mt-0 mb-1 font-bold">
                    {{ $t("check-booking.order-number") }}: {{bookingStatus && bookingStatus.orderNumber}}
                  </h4>

                  <div class="font-light mt-0 mb-1 font-bold border-custom dVacation-pack" v-if="product && flight">
                    <FlightOnlyItem
                      :flight="flight"
                      :categoryName="categoryCode"
                      type="check-booking"
                    />
                  </div>
                  <div class="font-light mt-4 mb-1 px-3 font-bold shadow-sm dVacation-pack" v-if="product && flight">
                    <h5>{{$t('check-booking.flight-remark')}}</h5>
                    <ul>
                      <li>{{$t('product-page.board')}}: {{ hotel.basisName}}</li>
                      <li>{{$t('check-booking.baggage')}}: {{ flight.FlightDetail[0].FL_Max_Weight}}kg</li>
                      <li>{{$t('product-page.hand-luggage')}}: {{ flight.FlightDetail[0].FL_Max_Weight_Hand}}kg</li>
                    </ul>
                  </div>
                  <div class="font-light mt-4 mb-1 px-3 font-bold shadow-sm dVacation-pack" v-if="hotel">
                    <FilterItem
                      :hotel="hotel"
                      type="booking-status"
                    />
                  </div>

                  <div class="font-light mt-4 mb-1 px-3 font-bold shadow-sm dVacation-pack" v-if="hotel">
                    <h4> {{ $t("check-booking.passenger-details") }}</h4>
                    <b-table responsive striped hover :items="paxItems" :fields="fields"></b-table>
                  </div>

                  <div class="font-bold font-light mb-1 mt-4 shadow-sm" v-if="termsList">
                    <strong class="tab-title mx-3 my-1">{{termsListName}}</strong>
                    <div class="row">
                      <div class="col-md-6 col-12">
                        <ul>
                          <li v-for="(term, inx) in termsList.slice(0,termsList.length / 2)" :key="inx">
                            <a :href="term[1].slug || term[1].link">{{ term[1].title }}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-6 col-12">
                        <ul>
                          <li v-for="(term, inx) in termsList.slice(termsList.length / 2)" :key="inx">
                            <a :href="term[1].slug || term[1].link">{{ term[1].title }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-tab>

            <b-tab>
              <template #title>
                <strong class="tab-title">{{$t("check-booking.hotel-status")}}</strong>
              </template>
              <b-card-text class="card-text-bold">
                <div class="font-light mt-4 mb-1 px-3 font-bold shadow-sm dVacation-pack" v-if="hotel">
                  <FilterItem
                    :hotel="hotel"
                    type="booking-status"
                  />
                  <p v-if="product">{{ product.hotels[0].address }}</p>
                </div>
                <div class="font-light mt-4 mb-1 px-3 font-bold shadow-sm dVacation-pack" v-if="product">
                  <h5>{{$t('booking.status')}}</h5>
                  <b-badge v-if="hotelStatus.OK" variant="success">{{ hotelStatus.OK }} OK - {{$t('check-booking.ok')}}</b-badge>
                  <b-badge v-if="hotelStatus.RQ" variant="danger">{{ hotelStatus.RQ }} RQ - {{$t('check-booking.rq')}}</b-badge>
                  <b-badge v-if="hotelStatus.CXXL" variant="danger">{{ hotelStatus.CXXL }} CXXL - {{$t('check-booking.cxxl')}}</b-badge>
                </div>
                <div class="font-light mt-4 mb-1 px-3 font-bold shadow-sm dVacation-pack" v-if="product">
                  <h5>{{$t('check-booking.hotel-details')}}</h5>
                  <p>{{ product.hotels[0].desc }}</p>
                  <Facilities v-for="(item, index) in product.hotels[0].fac" :key="index" :data="product.translations.fac[item.code][lang]"/>
                </div>
                <div class="clearfix"></div>
                <div class="font-light mt-4 mb-1 px-3 font-bold shadow-sm dVacation-pack" v-if="product && tabIndex === 1">
                  <Slider v-if="imageList.length" :slides="imageList" :destinationImage="destinationImage"/>
                </div>
              </b-card-text>
            </b-tab>

            <b-tab>
              <template #title>
                <strong class="tab-title">{{$t("check-booking.flight-status")}}</strong>
              </template>
              <b-card-text>

                <div class="font-light mt-4 mb-1 px-3 font-bold border-custom dVacation-pack" v-if="product && flight">
                  <FlightOnlyItem
                    :flight="flight"
                    :categoryName="categoryCode"
                    type="check-booking"
                  />
                </div>
                <div class="font-light mt-4 mb-1 px-3 font-bold shadow-sm dVacation-pack" v-if="product && flight">
                  <h5>{{$t('check-booking.flight-status')}}</h5>
                  <b-badge v-if="flightStatus===2" variant="success">{{ flightStatus }} OK - {{ $t("check-booking.flight-ok") }}</b-badge>
                  <b-badge v-else-if="flightStatus===1" variant="warning">{{ flightStatus }} OK - {{ $t("check-booking.flight-ok") }}</b-badge>
                  <b-badge v-else variant="danger">RQ - {{ $t("check-booking.flight-rq") }}</b-badge>
                </div>
                <div class="font-light mt-4 mb-1 px-3 font-bold shadow-sm dVacation-pack" v-if="product">
                    <h5>{{$t('check-booking.flight-remark')}}</h5>
                  <ul>
                    <li>{{$t('product-page.board')}}: {{ flight.FlightDetail[0].FL_Meal}}</li>
                    <li>{{$t('check-booking.baggage')}}: {{ flight.FlightDetail[0].FL_Max_Weight}}kg</li>
                    <li>{{$t('product-page.hand-luggage')}}: {{ flight.FlightDetail[0].FL_Max_Weight_Hand}}kg</li>
                  </ul>
                </div>
                <div class="font-light mt-4 mb-1 px-3 font-bold shadow-sm dVacation-pack please_mall_contain_body text-center" v-if="pdfUrlItinerary">
                  <h5>{{$t("check-booking.print-itinerary")}}</h5>
                  <div class="d-flex justify-content-between">
                    <div class="please_mall">
                      <div class="icon_box">
                        <b-link
                          :href="pdfUrlItinerary"
                          @click.prevent="downloadTickets(pdfUrlItinerary, 'Itinerary')"
                        ><img :src="`${speedSizeDomain}/assets/img/after-booking-icon16.png`" alt="after-booking" width="100" height="100"
                        /></b-link>
                      </div>
                      <h5>{{ $t('check-booking.itinerary-printing') }}</h5>
                    </div>
                  </div>
                </div>

              </b-card-text>
            </b-tab>

            <b-tab>
              <template #title>
                <strong class="tab-title">{{$t("check-booking.my-document")}}</strong>
              </template>
              <b-card-text>

                <h5 class="text-center">{{$t("check-booking.print-document")}}</h5>

                <div class="please_mall_contain_body text-center">
                  <div :class="device==='desktop'? 'd-flex': ''">

                    <div class="please_mall p-3 shadow-sm" v-if="pdfUrlItinerary">
                      <div class="icon_box">
                        <b-link
                          :href="pdfUrlItinerary"
                          @click.prevent="downloadTickets(pdfUrlItinerary, 'Itinerary')"
                        ><img :src="`${speedSizeDomain}/assets/img/after-booking-icon16.png`" alt="after-booking" width="100" height="100"
                        /></b-link>
                      </div>
                      <h5>{{ $t('check-booking.itinerary-printing') }}</h5>
                    </div>

                    <div class="please_mall p-3 shadow-sm" v-if="pdfUrlTerms">
                      <div class="icon_box">
                        <b-link
                          :href="pdfUrlTerms"
                          @click.prevent="downloadTickets(pdfUrlTerms, 'UrlTerms')"
                          ><img :src="`${speedSizeDomain}/assets/img/after-booking-icon15.png`" alt="after-booking" width="100" height="100"
                        /></b-link>
                      </div>
                      <h5>{{ $t('check-booking.voucher-printing') }}</h5>
                    </div>

                    <div class="please_mall p-3 shadow-sm" v-if="pdfUrlHotelVoucher">
                      <div class="icon_box">
                        <b-link
                          :href="pdfUrlHotelVoucher"
                          @click.prevent="downloadTickets(pdfUrlHotelVoucher, 'HotelVoucher')"
                          ><img :src="`${speedSizeDomain}/assets/img/after-booking-icon18.png`" alt="after-booking" width="100" height="100"
                        /></b-link>
                      </div>
                      <h5>{{ $t('check-booking.voucher-printing') }}</h5>
                    </div>

                    <div class="please_mall p-3 shadow-sm" v-if="pdfUrlTicket">
                      <div class="icon_box">
                        <b-link
                          :href="pdfUrlTicket"
                          @click.prevent="downloadTickets(pdfUrlTicket, 'Ticket')"
                          ><img :src="`${speedSizeDomain}/assets/img/after-booking-icon17.png`" alt="after-booking" width="100" height="100"/>
                        /></b-link>
                      </div>
                      <h5>{{ $t('check-booking.flight-ticket-printing') }}</h5>
                    </div>

                    <div class="please_mall p-3 shadow-sm" v-if="pdfUrlReceipt">
                      <div class="icon_box">
                        <b-link
                          :href="pdfUrlReceipt"
                          @click.prevent="downloadTickets(pdfUrlReceipt, 'HotelVoucher')"
                          ><img :src="`${speedSizeDomain}/assets/img/after-booking-icon14.png`" alt="after-booking" class="col-10" width="100" height="100"/>
                        /></b-link>
                      </div>
                      <h5>{{ $t('check-booking.reception-printing') }}</h5>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-tab>

            <b-tab>
              <template #title>
                <strong class="tab-title">{{$t("check-booking.question-answer")}}</strong>
              </template>
              <b-card-text class="card-text">

                <div class="font-light mt-4 mb-1 px-3 font-bold shadow-sm dVacation-pack" v-if="questionAnswerList.length">
                  <b-card no-body class="mb-1" v-for="(item, inx) in questionAnswerList" :key="inx">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle="`accordion-${inx}`" variant="info">{{item.question}}</b-button>
                    </b-card-header>
                    <b-collapse :id="`accordion-${inx}`" :visible="inx===0" accordion="my-accordion" role="tabpanel">
                      <b-card-body>
                        <b-card-text>{{ item.answer }}</b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>

              </b-card-text>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>

  </div>
  <Footer v-if="!hideCondition"/>

  <b-modal v-model="modalConfirmTerms" centered :no-close-on-backdrop="true" :title="$t('check-booking.dear-client')" ok-only>
    <div class="w-100 text-right pr-5">{{$t('check-booking.please-confirm-terms')}}:</div>
    <div class="text-right">
      <label :class="['form-check-label', 'pr-5', !checkedTerms ? 'danger' : '']">
        <input
          type="checkbox"
          class="form-check-input"
          v-model="checkedTerms"
        />
        {{ $t('booking.i-agree-to') }}
        <a onclick="window.open(this.href, 'title', 'width=1200, height=600, top=300, left=100'); return false;" href="/%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_%D7%91%D7%99%D7%98%D7%95%D7%9C_%D7%95%D7%96%D7%99%D7%9B%D7%95%D7%99">
          <strong>{{ $t('booking.agree-desc') }}</strong>
        </a>
        {{ $t('booking.read-desc') }}
        <a onclick="window.open(this.href, 'title', 'width=1200, height=600, top=300, left=100'); return false;" href="/%D7%94%D7%A0%D7%97%D7%99%D7%95%D7%AA_%D7%9C%D7%99%D7%95%D7%A6%D7%90%D7%99%D7%9D_%D7%9E%D7%99%D7%A9%D7%A8%D7%90%D7%9C_%D7%95%D7%9C%D7%97%D7%95%D7%96%D7%A8%D7%99%D7%9D_%D7%9C%D7%99%D7%A9%D7%A8%D7%90%D7%9C">
          <strong>{{ $t('booking.read-desc-corona') }}</strong>
        </a>
      </label>
    </div>
    <b-alert :show="returnedMessage !== ''" variant="info" class="mt-3">{{ returnedMessage }}</b-alert>
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BButton, BFormInput, BTabs, BTab, BCardText, BModal, BAlert, BTable, BBadge,
  BCard, BCardBody, BCardHeader, BCollapse, VBToggle,
} from 'bootstrap-vue';
import dayjs from 'dayjs';
import gMixin from '@/utils/mixins';
import bannerImageDestination from '@/utils/bannerImageDestination';
import imageUrlMixin from '@/utils/imageUrlMixin';

/**
 * example link:
 * /check-booking?pnr=1652086&langCode=ENG&altHotelAcceptanceToken=1d374580-3bc1-468b-8495-7d3586c39542
 */

const { VUE_APP_CHANNEL_MOBILE_APP } = process.env;

export default {
  name: 'visitor-login',
  components: {
    BButton,
    BFormInput,
    BTabs,
    BTab,
    BCardText,
    BTable,
    BModal,
    BAlert,
    BBadge,
    BCard,
    BCardBody,
    BCardHeader,
    BCollapse,
    FlightOnlyItem: () => import('@/components/searchResult/atoms/FlightOnlyItem'),
    FilterItem: () => import('@/components/searchResult/atoms/FilterItem'),
    Banner: () => import('@/components/productPage/ProductBanner'),
    Footer: () => import('@/components/HeaderFooter/Footer'),
    Facilities: () => import('@/components/productPage/atoms/Facilitie'),
    Slider: () => import('@/components/productPage/ProductSlider'),
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [gMixin, bannerImageDestination, imageUrlMixin],
  data() {
    return {
      verifyCode: '',
      checkedTerms: false,
      modalConfirmTerms: false,
      acceptedTerms: false,
      returnedMessage: '',
      pnr: '',
      acceptanceToken: '',
      accepted: false,
      tabIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      isVisitorLogin: 'IS_VISITOR_AUTHORIZED',
      errorMessage: 'GET_VISITOR_ERROR_MESSAGE',
      visitorSid: 'GET_VISITOR_SID',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
      limitDealsState: 'GET_LIMIT_DEALS_STATE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      bookingInfo: 'GET_BOOKING_INFORMATION',
      product: 'GET_PRODUCT',
      questionAnswer: 'GET_QUESTION_ANSWER',
      destinationImages: 'GET_DESTINATION_IMAGES',
      footerPagesList: 'GET_FOOTER_PAGES_LIST',
      footerCategoryList: 'GET_FOOTER_CATEGORY_LIST',
    }),
    isMobileApp() {
      return this.typeChannel === VUE_APP_CHANNEL_MOBILE_APP;
    },
    hideCondition() {
      const { bypassPaymentState, limitDealsState, isOdysseySite } = this;
      return (!isOdysseySite && (bypassPaymentState || this.device !== 'desktop' || this.isMobileApp)) || limitDealsState;
    },
    bookingStatus() {
      const { bookingInfo } = this;
      if (!bookingInfo) return null;
      return bookingInfo.bookingStatus;
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('booking.name'),
        },
        {
          key: 'title',
          label: this.$t('booking.gender'),
        },
        {
          key: 'birthDay',
          label: this.$t('booking.date-birth'),
        },
        {
          key: 'mobileNumber',
          label: this.$t('login.phone-number'),
        },
        {
          key: 'emailAddress',
          label: this.$t('booking.email'),
        },
      ];
    },
    paxItems() {
      const { bookingInfo } = this;
      return bookingInfo?.personalInfo.map((item) => ({
        name: item.fullName,
        title: item.title,
        birthDay: item.birthDate,
        mobileNumber: item.mobileNumber,
        emailAddress: item.emailAddress,
      })) || [];
    },
    // flightsInfo() {
    //   const packProductInfo = this.bookingInfo?.packProductInfo;
    //   if (!packProductInfo?.flights) return null;
    //   const { flightsInfo, airlines } = packProductInfo;
    //   flightsInfo[0].airlineInfo = airlines[flightsInfo[0].airlineCode];
    //   flightsInfo[1].airlineInfo = airlines[flightsInfo[1].airlineCode];
    //   return {
    //     FlightDetail: flightsInfo,
    //   };
    // },
    hotel() {
      const { bookingInfo, lang } = this;
      if (!bookingInfo) return null;
      const hotelName = this.bookingInfo?.packSelection?.selectedPackInfo?.hotelName[lang] || this.bookingInfo?.packSelection?.selectedPackInfo?.hotelName.en;
      const hotelGrade = +(this.bookingInfo?.packSelection?.selectedPackInfo?.hotelGrade);
      const checkIn = this.bookingInfo?.moreInfo?.hotel?.checkinDate;
      const checkOut = this.bookingInfo?.moreInfo?.hotel?.checkoutDate;
      const basisName = this.bookingInfo?.packSelection?.conjugatePackages[this.bookingInfo?.packSelection.packageId]?.basisTranslations[lang];
      return {
        hotelName,
        hotelGrade,
        checkIn,
        checkOut,
        duration: dayjs(checkOut).diff(checkIn, 'day'),
        basisName,
      };
    },
    hotelStatus() {
      const { product, bookingInfo } = this;
      if (!product || !bookingInfo) return '';
      const roomTypeLst = bookingInfo.packProductInfo.roomType.map((r) => ({ class: r.roomClassCode, type: r.roomCompositionTypeCode }));
      const hotelList = product?.hotels?.filter((hotel) => (roomTypeLst.findIndex((room) => (room.class === hotel.Room_Class && room.type === hotel.Room_Type)) > -1)) || [];
      return {
        RQ: hotelList.filter((h) => h.status === 'RQ').length,
        OK: hotelList.filter((h) => h.status === 'OK').length,
        CXXL: hotelList.filter((h) => h.status === 'CXXL').length || hotelList.filter((h) => h.status === 'FREEZE').length,
      };
    },
    termsList() {
      const { footerPagesList } = this;
      if (!footerPagesList) return null;

      return Object.entries(footerPagesList).filter((item) => (item[1].active && item[1].category === 'CD' && item[0] !== 'AgreementApp'));
    },
    termsListName() {
      return this.footerCategoryList.find((cat) => cat.code === 'CD').nameTranslationEng;
    },
    categoryCode() {
      return this.bookingInfo?.packSelection?.selectedPackInfo?.packCategCode || '';
    },
    queryForProduct() {
      const { bookingInfo } = this;
      if (!bookingInfo) return null;

      const packId = bookingInfo.packSelection.packageId;
      const laId = bookingInfo.packSelection.hotelId;
      const categoryId = this.categoryCode;
      const dealTypeCode = this.categoryCode;
      const flights = bookingInfo.packSelection.roundtripFlightsId;
      const dateFrom = bookingInfo.packSelection.fromDate;
      return { packId, laId, categoryId, dealTypeCode, flights, dateFrom };
    },
    imageList() {
      const { product } = this;
      if (!product) return [];
      let list = [];
      if (product.perspectiveUrls) list = list.concat(product.perspectiveUrls);
      if (product.galleryUrls) list = list.concat(product.galleryUrls);
      if (list.length === 0) list.push(...product.hotels[0]?.imgs);
      if (list.length === 0) list.push(`${this.speedSizeDomain}/assets/img/has_no_picture.jpg`);
      return list;
    },
    destinationImage() {
      const { product, destinationImages } = this;
      if (!product || !destinationImages) return '';
      const dest = product.destination_1;
      const matchedItem = destinationImages.find((d) => d.code === dest);
      const url = (matchedItem) ? destinationImages.find((d) => d.code === dest).imageUrls[0] : '';
      return url;
    },
    flight() {
      const { product } = this;
      if (!product) return [];
      const fly = product.flights?.[0] || null;
      if (fly === null) return null;

      let barCode = fly?.FlightDetail?.[0]?.FL_AIRLINE || '';
      const airInfoDepart = (product.airlineInfo && product.airlineInfo[barCode]) ? this.product.airlineInfo[barCode] : null;

      barCode = this.product?.flights?.[0]?.FlightDetail[1]?.FL_AIRLINE || '';
      const airInfoReturn = (this.product.airlineInfo && this.product.airlineInfo[barCode]) ? this.product.airlineInfo[barCode] : null;

      fly.FlightDetail[0].airlineInfo = airInfoDepart;
      fly.FlightDetail[1].airlineInfo = airInfoReturn;

      return fly;
    },
    flightStatus() {
      const { product } = this;
      if (!product) return 0;
      const flyStatusList = product.flights[0].FlightDetail.map((f) => (f.FL_Flt_Status));
      return flyStatusList.filter((f) => f?.toLowerCase() === 'open').length;
    },
    pdfUrlItinerary() {
      const { bookingInfo } = this;
      return bookingInfo?.afterBookingService?.pdfUrlItinerary || null;
    },
    pdfUrlTerms() {
      const { bookingInfo } = this;
      return bookingInfo?.afterBookingService?.pdfUrlTerms || null;
    },
    pdfUrlHotelVoucher() {
      const { bookingInfo } = this;
      return bookingInfo?.afterBookingService?.pdfUrlHotelVoucher || null;
    },
    pdfUrlTicket() {
      const { bookingInfo } = this;
      return bookingInfo?.afterBookingService?.pdfUrlTicket || null;
    },
    pdfUrlReceipt() {
      const { bookingInfo } = this;
      return bookingInfo?.afterBookingService?.pdfUrlReceipt || null;
    },
    termsAgreementToken() {
      const { query } = this.$route;
      return query?.termsAgreementToken || false;
    },
    questionAnswerList() {
      const { questionAnswer } = this;
      if (!questionAnswer) return [];
      return questionAnswer.map((q) => ({ question: q.question, answer: q.answer }));
    },
  },
  watch: {
    verifyCode() {
      this.$store.commit('SET_VISITOR_VERIFY_CODE', this.verifyCode);
    },
    visitorSid() {
      this.verifyCode = '';
    },
    async isVisitorLogin() {
      if (this.isVisitorLogin) {
        await this.$store.dispatch('FETCH_BOOKING_INFORMATION');
        this.fetchProduct();
        await this.$store.dispatch('FETCH_QUESTION_ANSWER');
      }
    },
    async checkedTerms() {
      if (this.checkedTerms) {
        this.returnedMessage = await this.$store.dispatch('SEND_CONFIRM_WITH_TERMS', this.termsAgreementToken);
        if (this.returnedMessage !== '') this.checkedTerms = false;
      }
    },
  },
  created() {
    this.$store.commit('SET_CURRENT_PAGE', 'check-booking');
    this.$store.dispatch('VERIFY_VISITOR_LOGIN_STATE');
    this.pnr = this.$route?.query?.pnr || '';
    this.acceptanceToken = this.$route?.query?.acceptanceToken || '';
    if (this.pnr) this.requestLogin();
  },
  methods: {
    checkPnr(e) {
      if (e.keyCode === 13) {
        this.requestLogin();
      }
    },
    checkCode(e) {
      if (e.keyCode === 13) {
        this.requestVerifyCode();
      }
    },
    async requestLogin() {
      if (this.pnr.trim()) {
        await this.$store.dispatch('REQUEST_VISITOR_LOGIN', this.pnr);
      }
    },
    async requestVerifyCode() {
      await this.$store.dispatch('REQUEST_VISITOR_BY_VERIFY_CODE');
    },
    async cancelLogin() {
      if (this.isVisitorLogin) await this.$store.dispatch('REQUEST_VISITOR_LOGOUT');
      window.location = '/';
    },
    async fetchProduct() {
      const { queryForProduct } = this;
      if (queryForProduct) {
        await this.$store.dispatch('FETCH_PRODUCT', queryForProduct);

        await this.fetchBannerImageByDestination();
      }
    },
    downloadTickets(pURL, pName) {
      this.$bvToast.toast(this.$t('booking.waiting-downloading'), {
        title: this.$t('booking.downloading'),
        autoHideDelay: 10 * 1000,
        appendToast: true,
        variant: 'info',
        noCloseButton: true,
        bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
      });
      const fileName = `${pName}.pdf`;
      if (!window.ActiveXObject) {
        const save = document.createElement('a');
        save.href = pURL;
        save.target = '_blank';
        save.download = fileName;
        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
          document.location = save.href;
        } else {
          const evt = new MouseEvent('click');
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
      } else if (!!window.ActiveXObject && document.execCommand) {
        const _window = window.open(pURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName);
        _window.close();
      }
    },
    async sendAcceptedRequest() {
      await this.$store.dispatch('REQUEST_ACCEPTED_STATE_FOR_CHANGED_HOTEL', { accepted: this.accepted, acceptanceToken: this.acceptanceToken });
    },
  },
};
</script>
<style>
.booking-info-tab .card-header-tabs {
  padding: 0;
}
</style>
<style scoped>
.border-custom{
  border: 7px solid #dee2e6 !important;
}
.clearfix {
  clear: both;
}
.danger {
  border-radius: 5px;
  border: solid 1px #f00;
  border-color: #FF5733;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(233 102 102 / 60%);
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-right: -1.25rem;
}
a {
  font-size: 14px;
  line-height: 1.7;
  /* color: #666666; */
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

/*---------------------------------------------*/
h1,h2,h3,h4,h5,h5 {
  margin: 0px;
}

p {
  /* font-size: 14px; */
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

.limiter ul, .limiter li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #999999; }
input:-moz-placeholder { color: #999999; }
input::-moz-placeholder { color: #999999; }
input:-ms-input-placeholder { color: #999999; }

textarea::-webkit-input-placeholder { color: #999999; }
textarea:-moz-placeholder { color: #999999; }
textarea::-moz-placeholder { color: #999999; }
textarea:-ms-input-placeholder { color: #999999; }

/*---------------------------------------------*/

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
  font-size: 16px;
  color: #cccccc;
  line-height: 1.4;
}
.error-message {
  color: #fb7575;
  text-shadow: 1px 1px black;
  font-size: 1.5rem;
}

.bo1 {
  border-bottom: 1px solid #999999;
}

.hov1:hover {
  border-color: #d33f8d;
}

/*//////////////////////////////////////////////////////////////////
[ login ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: calc(100vh - 14.1rem);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  position: relative;
  z-index: 1;
}

.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #005bea;
  background: -webkit-linear-gradient(to bottom, #005bea, #00c6fb);
  background: -o-linear-gradient(to bottom, #005bea, #00c6fb);
  background: -moz-linear-gradient(to bottom, #005bea, #00c6fb);
  background: linear-gradient(to bottom, #005bea, #00c6fb);
  opacity: 0.9;
}

.wrap-login100 {
  width: 390px;
  background: transparent;
}

/*------------------------------------------------------------------
[  ]*/
.login100-form {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.login100-form-title {
  font-size: 24px;
  color: #fff;
  line-height: 1.2;
  text-align: center;

  width: 100%;
  display: block;
}

/*---------------------------------------------*/
.login100-form-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.login100-form-avatar img {
  width: 100%;
}

/*---------------------------------------------*/
.wrap-input100 {
  position: relative;
  width: 100%;
  z-index: 1;
}

.input100 {
  font-size: 15px;
  line-height: 1.2;
  color: #333333;

  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  padding: 0 53px 0 53px !important;
  border-style: solid;
  border-width: 2px;
  background-position: left calc(0.375em + 0.5rem) center;
}

/*------------------------------------------------------------------
[ Focus ]*/
.focus-input100 {
  display: block;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: rgba(0,91,234, 0.6);
}

.input100:focus + .focus-input100 {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}

.symbol-input100 {
  font-size: 15px;
  color: #999999;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-right: 30px;
  pointer-events: none;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100 + .symbol-input100 {
  color: #00c6fb;
  padding-right: 23px;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  font-size: 15px;
  line-height: 1.5;
  color: #e0e0e0;

  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: #333333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  position: relative;
  z-index: 1;
}

.login100-form-btn::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  top: 0;
  left: 0;
  background: #005bea;
  background: -webkit-linear-gradient(to left, #005bea, #00c6fb);
  background: -o-linear-gradient(to left, #005bea, #00c6fb);
  background: -moz-linear-gradient(to left, #005bea, #00c6fb);
  background: linear-gradient(to left, #005bea, #00c6fb);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  opacity: 0;
}

.login100-form-btn:hover {
  background: transparent;
  color: #fff;
}

.login100-form-btn:hover:before {
  opacity: 1;
}

/*------------------------------------------------------------------
[ Button sign in with ]*/
.btn-face,
.btn-google {
  font-size: 16px;
  line-height: 1.2;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc((100% - 10px) / 2);
  height: 40px;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  background-color: #fff;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.btn-face {
  color: #3b5998;
}

.btn-face i {
  font-size: 20px;
  margin-right: 10px;
  padding-bottom: 1px;
}

.btn-google {
  color: #555555;
}

.btn-google img {
  width: 19px;
  margin-right: 10px;
  padding-bottom: 1px;
}

.btn-face:hover,
.btn-google:hover {
  border-color: #d33f8d;
}

@media (max-width: 576px) {
  .wrap-login100 {
    padding-top: 80px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .container-login100 {
    min-height: calc(100vh - 7.5rem);
  }
}

.please_mall_area {
  margin-bottom: 25px;
}
.please_mall_area .plase_mall_contain_box {
  border: 1px solid #949494;
}
.please_mall_area .plase_mall_contain_box h3 {
  padding: 20px 20px;
  border-bottom: 1px solid #949494;
  font-size: 24px;
  text-transform: uppercase;
}
.please_mall_area .plase_mall_contain_box h3 span {
  color: #0763ab;
}
.please_mall_area .please_mall_contain_body {
  padding: 30px 20px;
}
.please_mall_contain_body .please_mall {
  width: 100%;
  text-align: center;
}
.please_mall_contain_body .please_mall .icon_box {
  width: 88px;
  height: 82px;
  border: 1px solid #0763ab;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 15px;
}
.please_mall_contain_body .please_mall h5 {
  text-transform: uppercase;
  color: #000;
  margin: 0 0;
  font-size: 16px;
}
.please_mall_area .img_box {
  height: 275px;
  overflow: hidden;
  border-radius: 5px;
}
.tab-title {
  font-size: 1.3rem;
}
.card-text-bold p {
  font-size: 1.2rem;
}
.card-text p, .card-text td {
  font-size: 1rem;
}
</style>
